import css from "./Footer.module.css";
import Logo from "../../assets/logo.png";
import {
  PhoneIcon,
  LoginIcon,
  UsersIcon,
  LinkIcon,
} from "@heroicons/react/outline";

const Footer = () => {
  return (
    <div className={css.cFooterWrapper}>
      <hr />

      <div className={css.cFooter}>
        <div className={css.logo}>
          <img src={Logo} alt="logo" />
          <span>MyBeauty</span>
        </div>

        <div className={css.block}>
          <div className={css.details}>
            <span>Contact Us!</span>
            <span className={css.pngLine}>
              <span className={css.pngLine}>
                <PhoneIcon className={css.icon} />
                <span>Call us!</span>
              </span>
            </span>
          </div>
        </div>

        <div className={css.block}>
          <div className={css.details}>
            <span>Resources!</span>
            <span className={css.pngLine}>
              <LinkIcon className={css.icon} />
              <span>Safety and Privacy</span>
            </span>
          </div>
        </div>
        <div className={css.block}>
          <div className={css.details}>
            <span>Company!</span>
            <span className={css.pngLine}>
              <UsersIcon className={css.icon} />
              <span>About Us!</span>
            </span>
          </div>
        </div>

        <div className={css.block}>
          <div className={css.details}>
            <span>Account!</span>
            <span className={css.pngLine}>
              <LoginIcon className={css.icon} />
              <span>Sign in!</span>
            </span>
          </div>
        </div>
      </div>

      <div className={css.copyRight}>
        <span>CopyRight ©2023, Khaled</span>
        <span>All Rights Reserved</span>
      </div>
    </div>
  );
};

export default Footer;
