import css from "./Testimonial.module.css";
import Hero from "../../assets/testimonialHero.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { TestimonialsData } from "../../data/testimonials";

const Testimonial = () => {
  return (
    <div className={css.testimonials}>
      <div className={css.wrapper}>
        <div className={css.container}>
          <span>Top Rated!</span>
          <span>
            Seedily say has suitable disposal and boy. Exercise joy man children
            rejoiced !
          </span>
        </div>
        <img src={Hero} alt="hero" />

        <div className={css.container}>
          <span>100K</span>
          <span>Happy Customers With Us!</span>
        </div>
      </div>

      <div className={css.reviews}>Reviews</div>
      <div className={css.carousal}>
        <Swiper
          slidesPerView={4}
          slidesPerGroup={1}
          spaceBetween={40}
          loop={true}
          breakpoints={{
            856: {slidesPerView: 3},
            640: {slidesPerView: 2},
            0:{slidesPerView:1}
          }}
          className={css.tCarousel}>
          {TestimonialsData.map((testimonial, i) => (
            <SwiperSlide key={i}>
              <div className={css.testimonial}>
                <img src={testimonial.image} alt="img" />
                <span>{testimonial.comment}</span>
                <hr />
                <span>{testimonial.name}</span>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Testimonial;
